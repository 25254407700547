import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Box, Grid } from "@mui/material";
import "./style.css";

const App = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#2D2D2D",
        overflow: "hidden",
      }}
    >
      <Grid container sx={{ width: "100%", height: "110%" }}>
        <Grid item sm={12} lg={12} md={12} xl={12}>
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: "c1c94858-45ea-48a2-a641-3c8b4a03c9d9",
              embedUrl:
                "https://app.powerbi.com/view?r=eyJrIjoiZTA3ZTE0ODEtMzBhZC00MzQzLThiMzQtM2QwMTE2NjkzNTVjIiwidCI6IjUyMThlM2IxLWM0YmEtNDcwNi05OWY5LWE2YjBkNTU0OThiNCJ9",
              accessToken:
                "eyJrIjoiOTE5MTZlMWEtYmRiNi00NzRjLTk1NTYtZWJiYTQ2MWU5NWVlIiwidCI6IjUyMThlM2IxLWM0YmEtNDcwNi05OWY5LWE2YjBkNTU0OThiNCJ9",
              tokenType: models.TokenType.Aad,
              settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: false,
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log("jnknk", event.detail);
                  },
                ],
              ])
            }
            cssClassName={"Embed-container"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;
